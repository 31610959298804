body {
  margin: 0;
  font-family: "JannaLT", sans-serif;
  background-color: #f4f6f9 !important;
  max-height: 100vh;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.pb-16 {
  padding-bottom: 64px;
}

.pt-14 {
  padding-top: 56px;
}

.MuiTooltip-tooltip {
  background-color: #e9f1e7;
  text-align: center;
  padding: 6px;
  font-size: 12px;
  border-radius: 8px;
  opacity: 1;
  color: #000;
  font-weight: 400;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  top: 20px;
  left: 10px;
}

*::-webkit-scrollbar {
  display: none !important; /* Safari and Chrome */
}
